.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  color: "white";
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-inner {
  position: relative;
  padding: 32px;
  justify-content: center;
  align-items: center;
  max-width: 640px;
  max-height: 640px;
  min-width: 128px;
  min-height: 320px;
  width: 280px;
  height: 350px;
  margin: auto;
  text-align: center;
  background-color: #ffff;
  border-radius: 20px !important;
}

.popup-inner .close-btn {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 5px;
  height: 25px;
  justify-content: center;
}
.popup-inner .continue-btn {
  background-color: #ff6262;
  justify-content: center;
  font-size: small, auto;
}

.popup-inner .connect-btn {
  position: center;
  background-color: #ff6262;
  justify-content: center;
  color: #ffff;
}
