// Bootstrap carousel
.carousel-mobile-layout {
  position: relative;
  padding: 20px;
  max-width: 670px;
  margin-left: auto;
  margin-right: auto;
}

.carousel-viewport-mobile-layout {
  overflow: hidden;
  width: 100%;
}

.carousel-container-mobile-layout {
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.slide-mobile-layout {
  position: relative;
  min-width: 100%;
}

.slide-interior-mobile-layout {
  position: relative;
  overflow: hidden;
  height: max-content;
}

.testimonial-pic {
  width: 75px;
  height: 75px;
  border-radius: 50px;
}

.icon-button-mobile-layout {
  outline: 0;
  cursor: pointer;
  background-color: transparent;
  touch-action: manipulation;

  z-index: 1;

  transform: translateY(-50%);
  border: 0;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  color: #8f8f8f;
  padding: 0;
}
